const baseUrl = "https://api.namameditation.com/";
// const baseUrl = "http://192.168.1.43:10100/";
// const baseUrl = "https://namaapi.appdeft.in/";

const endPointUrl = baseUrl + "api/user";

// Test
// const razorPayKeyId = "rzp_test_IqTLn28nIEr9VH";

// Live
const razorPayKeyId = "rzp_live_SFnYzkH82xTXlh";
// const appUrl = 'https://namaweb.appdeft.in/#/membership';
const appUrl = 'https://membership.namameditation.com/#/membership';

const constants = { baseUrl, endPointUrl, razorPayKeyId, appUrl };

export default constants;
